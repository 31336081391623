  <template>
  <div class="ListHot">
    <div class="_box">
      <div class="_title">
        {{title}}
      </div>
      <div class="_list">
        <ul>
          <li v-for="item,i in list" :key="i">
            <div>
              <a @click="goto(item.id)"> {{item.title}}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListHot",
  components: {},
  props: {
    title: String,
    list: Object,
  },
  created() {},
  data() {
    return {
      value: false,
    };
  },
  methods: {
    goto(e) {
      window.open('/article?id='+e+'&category=', '_blank');
      // this.$router.push("/article?id=" + e);
    },
  },
};
</script>

<style lang="scss">
</style>
