  <template>
  <div class="ListItem">
    <div class="_box">
      <div class="_title">
        {{keys?keys:category.title}}
      </div>
      <div  v-if="category.child.length == 0">
        <div class="_list">
          <ul>
            <li v-for="item,i in list" :key="i" :class="i==4?'is_line':''" @click="goto(item.id)">
              <div class="_top">
                <div class="item_title">
                  <a>{{item.title}}</a>
                </div>
                <div class="_time">{{item.created}}</div>
              </div>
              <div class="_desc">{{item.describe}}</div>
            </li>
          </ul>
        </div>
        <div class="_page">
          <el-pagination background layout="prev, pager, next" :total="total" @current-change='next'>
          </el-pagination>
        </div>
      </div>
      <div v-else style="margin-top: 30px">
        <Childitem :category="item" v-for="item,i in category.child" :key="i"></Childitem>
      </div>
    </div>
  </div>
</template>

<script>
import Childitem from "../components/Childitem.vue";

export default {
  name: "home",
  components: {Childitem},
  props: {
    category: Object,
    keys: String,
  },
  watch: {
    keys: {
      handler(newVal) {
        this.getList();
      },
      deep: true,
    },
    category: {
      handler(newVal) {
        this.getList();
      },
      deep: true,
    },
    // $route: {
    //   handler: function (val, oldVal) {
    //     this.getList();
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.getList();
  },
  data() {
    return { 
      page: 0,
      total: 0,
      list: [],
    };
  },
  methods: {
    next(e) {
      this.page = e;
      this.getList();
    },
    goto(e) {
      window.open('/article?id='+e+'&category='+this.category.id, '_blank');
      // this.$router.push("/article?id=" + e + "&category=" + this.category.id);
    },
    getList() {
      if (this.category.child.length > 0) {
          return;
      }
      this.$api.get({
        url: "index/list",
        data: { category: this.category.id, page: this.page, key: this.keys },
        success: (res) => {
          this.total = res.data.total;
          this.list = res.data.data;
        },
      });
    },
  },
};
</script>

<style lang="scss">
</style>