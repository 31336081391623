  <template>
  <div class="list com">
    <div class="list-list">
      <ListTitle :category="category"></ListTitle>
      <ListItem :category='category' :keys='key' v-if="category || key"></ListItem>
    </div>
    <div class="list-hot">
      <ListSearch></ListSearch>
      <ListHot title='最新发布' :list='hot'></ListHot>
      <ListHot title='浏览最多' :list='look'></ListHot>
    </div>
  </div>
</template>

<script>
import ListTitle from "../components/ListTitle.vue";
import ListSearch from "../components/ListSearch.vue";
import ListItem from "../components/ListItem.vue";
import ListHot from "../components/ListHot.vue";

export default {
  name: "home",
  components: { ListTitle, ListSearch, ListItem, ListHot },
  created() {
    this.id = this.$route.query.id;
    this.key = this.$route.query.key;
    this.getList();
  },
  watch: {
    $route(to, from) {
      this.id = this.$route.query.id;
      this.key = this.$route.query.key;
      this.getList();
    },
  },
  data() {
    return {
      id: false,
      category: false,
      hot: [],
      look: [],
      key: false,
    };
  },
  methods: {
    getList() {
      this.$api.get({
        url: "index/category",
        data: { id: this.id },
        success: (res) => {
          this.category = res.data.category;
          this.hot = res.data.hot;
          this.look = res.data.look;
        },
      });
    },
  },
};
</script>

<style lang="scss">
</style>
