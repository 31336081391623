 <template>
  <div class="ListSearch">
    <input type="text" v-model="keys"  placeholder="搜索页面内容">
    <div class="search" @click="goto()"></div>
  </div>
</template>

<script>
export default {
  name: "ListSearch",
  components: {},
  created() {},
  data() {
    return {
      keys: '',
    };
  },
  methods: {
    goto(){
      this.$router.push('/list?key='+this.keys)
    }
  },
};
</script>

<style lang="scss">
</style>
