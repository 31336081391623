 <template>
  <div class="title">
    <div class="title-content">
      【 {{name}} 】
    </div>
    <div class="title-more" @click="goto">
      更多>>
    </div>
  </div>
</template>

<script>
export default {
  name: "titles",
  components: {},
  props: {
    name: {
      type: String,
    },
    more: {
      type: String,
    },
  },
  created() {},
  methods: {
      goto(){
          this.$router.push(this.more)
      }
  },
};
</script>