  <template>
  <div class="ListTitle">
     <div class="_info">
       <p>当前位置: <a href="/">首页</a> > <a @click="goto">{{category.title}}</a></p>
     </div>
  </div>
</template>

<script>
export default {
  name: "ListTitle",
  components: {},
  props:{
    category:Object
  },
  created() {},
  data() {
    return {
      value: false,
    };
  },
  methods: {
    goto(){
      this.$router.push('/list?id='+this.category.id)
    }
  },
};
</script>

<style lang="scss">
</style>
